import React, { useRef, useEffect, useState } from 'react';
import { List, Filter, ReferenceInput, AutocompleteInput, Link } from 'react-admin';
import { GoogleMap, Marker, InfoWindow, withScriptjs, withGoogleMap  } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { compose, withProps } from 'recompose';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import SiteReferenceInput from '../sites/SiteReferenceInput';

import deviceColors from './deviceColors';
import { GOOGLE_MAP_API_KEY } from '../../services/settings';


const useStyles = makeStyles({
	container: {
		marginTop: '30px',

		'& div:focus': {
			outline: 0
		}
	},
	divider: {
		margin: '5px 0'
	}
});

const customerOptionText = choice => choice.name ? choice.name : '';

const DeviceFilter = (props) => (
	<Filter {...props} variant="outlined">
		<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
			<AutocompleteInput optionText={customerOptionText} />
		</ReferenceInput>
		<SiteReferenceInput alwaysOn filter={{ customer_id: props.filterValues.customer_id }} />
	</Filter>
);

const CustomMarker = ({ data, ...props}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const classes = useStyles();

	const toggleTooltip = () => {
		setTooltipOpen(!tooltipOpen);
	};

	const closeTooltip = () => {
		setTooltipOpen(false);
	};

	if (!data.lat || !data.lng) {
		return null;
	}

	return (
		<Marker
			position={{ lat: data.lat, lng: data.lng }}
			icon={{
				path: "M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
				fillColor: deviceColors[data.online_status],
				fillOpacity: 1,
				strokeWeight: 1,
				strokeColor: '#FFF',
				scale: 2,
			}}
			onClick={toggleTooltip}
			{...props}
		>
			{tooltipOpen && (
				<InfoWindow onCloseClick={closeTooltip}>
					<>
						<Link to={`/devices/${data.id}/show`}><Typography><b>{data.name}</b></Typography></Link>
						<Typography>{data.serial}</Typography>
						<Typography>{data.product_name}</Typography>
						<Divider className={classes.divider} />
						{data.customer && <Typography>Customer: <Link to={`/customers/${data.customer.id}/show`}>{data.customer.name}</Link></Typography>}
						{data.site && <Typography>Site: <Link to={`/sites/${data.site.id}/show`}>{data.site.name}</Link></Typography>}
						<Typography>Group name: {data.group_name}</Typography>
						<Typography gutterBottom>IC server name: {data.ic_server_name}</Typography>
					</>
				</InfoWindow>
			)}
		</Marker>
	);
};

const Map = compose(
	withProps(props => ({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: props.height || `600px` }} />,
		mapElement: <div style={{ height: `100%` }} />,
		defaultZoom: 5,
		defaultCenter: {lat: 46.64, lng: 10.45 }
	})),
	withScriptjs,
	withGoogleMap,
)(({ ids, data, total, basePath, ...props }) => {
	const mapRef = useRef(null);

	useEffect(() => {
		fitBounds();
	}, [ids]); // eslint-disable-line react-hooks/exhaustive-deps

	const fitBounds = () => {
		if (ids.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			ids.forEach(id => {
				if (data[id].lat && data[id].lng) {
					bounds.extend({ lat: data[id].lat, lng: data[id].lng });
				}
			});
			mapRef.current.fitBounds(bounds);
		}
	};

	return (
		<>
			<GoogleMap ref={mapRef} {...props}>
				<MarkerClusterer>
					{ids.map(id => (
						<CustomMarker key={`marker_${id}`} data={data[id]} />
					))}
				</MarkerClusterer>
			</GoogleMap>
			<p>{ids.length} of {total} devices</p>
		</>
	);
});

const MapDeviceList = ({ staticContext, ...props }) => {
	const classes = useStyles();

	return (
		<List {...props} className={classes.container}>
			<Map />
		</List>
	);
};

MapDeviceList.defaultProps = {
	title: "Map",
	resource: "devices",
	basePath: "devices/map",
	hasList: true,
	hasShow: false,
	hasCreate: false,
	hasEdit: false,
	filters: <DeviceFilter />,
	filter: { map: true, in_stock: false, page_size: 500 },
	exporter: false,
	pagination: null,
};

export default MapDeviceList;