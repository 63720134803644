import React from 'react';
import { Link } from 'react-admin';
import { Marker, Popup } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import deviceColors from '../devices/deviceColors';

const useStyles = makeStyles({
    divider: {
        margin: '5px 0'
    }
});

const createIcon = (data, selected) => {
    const patternId = 'device';
    const color = deviceColors[data?.online_status] || deviceColors['unknown'];
    let colorStroke = 'white';
    if (selected) {
        colorStroke = color;
    }

    return (
        new DivIcon({
            html: `
            	<svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            		<path d="M13.2007 34.9471C13.2791 35.0292 13.3879 35.0758 13.5011 35.0758C13.5026 35.0758 13.5041 35.0758 13.5048 35.0758C13.6195 35.0743 13.729 35.0255 13.8067 34.9411C13.8356 34.9101 16.6972 31.7651 19.5195 27.6188C23.3335 22.0148 25.2679 17.3395 25.2679 13.7239C25.2679 7.21705 19.9894 1.92383 13.5011 1.92383C7.0128 1.92383 1.73438 7.21705 1.73438 13.7239C1.73438 17.5386 3.66874 22.2746 7.48418 27.8001C10.308 31.8894 13.1718 34.9167 13.2007 34.9471Z" fill="${color}" stroke="${colorStroke}" stroke-width="2.06615" stroke-miterlimit="10"/>
            		<circle cx="13.5" cy="14" r="11" />
                    <use x="4.5" y="5" height="18" width="18" href="#icon_${patternId}" />
                    <symbol id="icon_${patternId}" fill="white">
                        <svg viewBox="0 0 24 24">
                            <path d="M20.2 5.9l.8-.8C19.6 3.7 17.8 3 16 3s-3.6.7-5 2.1l.8.8C13 4.8 14.5 4.2 16 4.2s3 .6 4.2 1.7zm-.9.8c-.9-.9-2.1-1.4-3.3-1.4s-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM19 13h-2V9h-2v4H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM8 18H6v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2z"></path>
                        </svg>
                    </symbol>
            	</svg>
            `,
            iconSize: [27, 37],
            iconAnchor: [15, 0],
            className: ''
        })
    );
};

const DevicePopup = ({ data }) => {
    const classes = useStyles();

    return (
        <Popup>
            <Link to={`/devices/${data.id}/show`}><Typography><b>{data.name}</b></Typography></Link>
            <Typography>{data.serial}</Typography>
            <Typography>{data.product_name}</Typography>
            <Divider className={classes.divider} />
            {data.customer && <Typography>Customer: <Link to={`/customers/${data.customer.id}/show`}>{data.customer.name}</Link></Typography>}
            {data.site && <Typography>Site: <Link to={`/sites/${data.site.id}/show`}>{data.site.name}</Link></Typography>}
            <Typography>Group name: {data.group_name}</Typography>
            <Typography gutterBottom>IC server name: {data.ic_server_name}</Typography>
        </Popup>
    );
};

const DeviceMarker = ({ data, selected }) => {
    if (data.lat && data.lng) {
        return (
            <Marker
                key={data.id}
                position={[data.lat, data.lng]}
                icon={createIcon(data, selected)}
            >
                <DevicePopup data={data} />
            </Marker>
        );
    }
    return null;
};

export default DeviceMarker;