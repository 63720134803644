import React, { useState, useEffect, useRef } from 'react';
import { Filter, TextInput, ReferenceInput, AutocompleteInput, useQuery } from 'react-admin';
import { Map, TileLayer, LayersControl, FeatureGroup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

import StarlinkTerminalMarker from './StarlinkTerminalMarker';
import DeviceMarker from './DeviceMarker';
import SiteMarker from './SiteMarker';

import 'leaflet/dist/leaflet.css';
import './map.css';


const DEFAULT_CENTER = [46.64, 10.45];
const DEFAULT_ZOOM = 3;

const optionText = choice => choice.name ? choice.name : '';

const Filters = (props) => (
    <Filter {...props} variant="outlined">
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={optionText} />
        </ReferenceInput>
    </Filter>
);

const createCustomClusterIcon = cluster => {
    const count = cluster.getChildCount();
    let size;
    if (count < 10) {
        size = 30;
    } else if (count < 100) {
        size = 40;
    } else {
        size = 50;
    }

    return (
        L.divIcon({
            html: `<span>${count}</span>`,
            className: 'marker-cluster-custom',
            iconSize: L.point(size, size, true),
        })
    );
};

const LeafletMap = ({ children, center = DEFAULT_CENTER, zoom = DEFAULT_ZOOM, ...props }) => {
    const [filters, setFilters] = useState({});
    const [layers, setLayers] = useState(['Starlink terminals', 'Devices', 'Sites']);
    const [currentFilters, setCurrentFilters] = useState({});
    const [ready, setReady] = useState(false);
    const { data: sites } = useQuery({ type: 'getList', resource: 'sites', payload: { pagination: { page: 1, perPage: 500 }, sort: { field: 'id', order: 'DESC' }, filter: { ...currentFilters, map: true } } })
    const { data: starlinkTerminals } = useQuery({ type: 'getList', resource: 'starlink_terminals', payload: { pagination: { page: 1, perPage: 5000 }, sort: { field: 'id', order: 'DESC' }, filter: { ...currentFilters, map: true } } })
    const { data: devices } = useQuery({ type: 'getList', resource: 'devices', payload: { pagination: { page: 1, perPage: 500 }, sort: { field: 'id', order: 'DESC' }, filter: { ...currentFilters, map: true } } })
    const mapRef = useRef();

    const initBoundingBox = () => {
        const map = mapRef.current.leafletElement;
        let bounds = new L.LatLngBounds();
        sites.forEach(site => {
            bounds.extend([site.lat, site.lng]);
        });

        map.fitBounds(bounds);
    };

    useEffect(() => {
        if (sites?.length > 0) {
            setReady(true);
        }
    }, [sites]);

    useEffect(() => {
        if (ready) {
            initBoundingBox();
        }
    }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const timerId = setTimeout(() => {
            // TODO: improve later if more filters
            let newFilters = {};
            if (filters.q) {
                newFilters.q = filters.q;
            }
            if (filters.customer_id) {
                newFilters.customer_id = filters.customer_id;
            }
            setCurrentFilters(newFilters);
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [filters]);

    // Workaround to have clustering with overlays
    useEffect(() => {
        const map = mapRef.current.leafletElement;

        map.on('overlayadd', (e) => {
            if (e.name) {
                setLayers(state => [...state, e.name]);
            }
        });

        map.on('overlayremove', (e) => {
            if (e.name) {
                setLayers(state => state.filter(value => value !== e.name))
            }
        });
    }, []);

    return (
        <>
            <Filters setFilters={setFilters} />
            <div className="map-container">
                <Map center={center} zoom={zoom} ref={mapRef} {...props}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={23}
                        maxNativeZoom={19}
                    />

                    <MarkerClusterGroup showCoverageOnHover={false} iconCreateFunction={createCustomClusterIcon}>
                        {layers.includes('Starlink terminals') && (
                            starlinkTerminals?.map(data => (
                                <StarlinkTerminalMarker key={data.id} data={data} />
                            ))
                        )}
                        {layers.includes('Devices') && (
                            devices?.map(data => (
                                <DeviceMarker key={data.id} data={data} />
                            ))
                        )}
                        {layers.includes('Sites') && (
                            sites?.map(data => (
                                <SiteMarker key={data.id} data={data} />
                            ))
                        )}
                    </MarkerClusterGroup>

                    {/* Workaround to have clustering with overlays */}
                    <LayersControl position="bottomright">
                        <LayersControl.Overlay name="Starlink terminals" checked={layers.includes('Starlink terminals')}>
                            <FeatureGroup />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="Devices" checked={layers.includes('Devices')}>
                            <FeatureGroup />
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="Sites" checked={layers.includes('Sites')}>
                            <FeatureGroup />
                        </LayersControl.Overlay>
                    </LayersControl>

                    {children}
                </Map>
            </div>
        </>
    );
};

export default LeafletMap;