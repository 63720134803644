import React from 'react';
import {
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	BooleanInput,
} from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';
import { Typography } from '@material-ui/core';

const optionText = choice => choice.name ? choice.name : '';

const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<Typography variant="h7">A confirmation email will be sent to the user with instructions to set up their password and complete the account creation process.</Typography>
			<Typography variant="h6">General Information</Typography>
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<ReferenceInput label="Customer" source="customer_id" reference="customers" allowEmpty sort={{ field: "name", order: "ASC" }}>
				<AutocompleteInput optionText={optionText} />
			</ReferenceInput>

			<Typography variant="h6">Admin Roles</Typography>
			<BooleanInput label="is_admin" source="is_admin" />
			<BooleanInput label="is_staff" source="is_staff" />

			<Typography variant="h6">Admin Security</Typography>
      		<BooleanInput label="Enforce MFA" source="mfa_enabled" />

			<Typography variant="h6">Common Permissions</Typography>
			<BooleanInput label="Can Manage Users" source="can_manage_users" />

			<Typography variant="h6">Admin Permissions</Typography>
			<BooleanInput label="Can Manage Pricing" source="can_manage_pricing" />

			<Typography variant="h6">Portal Permissions</Typography>
			<BooleanInput label="Can View Invoices" source="can_view_invoices" />
			<BooleanInput label="Can View Packages" source="can_view_packages" />
			<BooleanInput label="Can View StarLinks" source="can_view_starlinks" />
			<BooleanInput label="Can View DataPools" source="can_view_data_pools" />
		</SimpleForm>
	</Create>
);

export default UserCreate;
