import React from 'react';
import { TextField, ReferenceField, SimpleShowLayout, FunctionField } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import RecordSplitButton from '../common/RecordSplitButton';
import StateChipField from './fields/StateChipField';
import TimeStampField from '../common/fields/MomentDateField';
import AlertsCountChipField from './fields/AlertsCountChipField';


const StarlinkTerminalDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="id" />
        <TextField source="terminal_id" label="Terminal ID" />
        <TextField source="kit_serial_number" />
        <TextField source="dish_serial_number" />
		<ReferenceField label="ServiceLine" source="starlink_service_line_id" reference="starlink_service_lines" link="show" allowEmpty>
			<TextField source="service_line_number" />
		</ReferenceField>
        <ReferenceField label="Account" source="starlink_account_id" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_name" />
        </ReferenceField>
        <ReferenceField label="Device" source="device_id" reference="devices" link="show" allowEmpty>
			<FunctionField render={record => `${record.name} / ${record.serial}`} />
		</ReferenceField>
        <StateChipField />
        <AlertsCountChipField source="last_alerts_count" sortable={false} />
        <TimeStampField label="Last online at" source="last_sync_at" showTime={true} />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <MapComponent height="300px" />
    </SimpleShowLayout>
);

const StarlinkTerminalRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkTerminalDrawer {...props} />} {...props}>
        <LinkFieldButton label="Site" source="site" reference="sites" allowEmpty link="show" sortable={false} basePath="sites">
			<TextField source="name" />
		</LinkFieldButton>
        <LinkFieldButton label="Terminal ID" sortBy="terminal_id">
            <TextField source="terminal_id" />
        </LinkFieldButton>
        <TextField label="KIT Serial" source="kit_serial_number" />
        <TextField label="Dish Serial" source="dish_serial_number" />
        <TextField label="IP" source="ip_address" />
        <LinkFieldButton label="ServiceLine" source="starlink_service_line" sortBy="starlink_service_line_id" basePath="starlink_service_lines" allowEmpty>
			<TextField source="service_line_number" />
		</LinkFieldButton>
        <ReferenceField label="Account" source="starlink_account" reference="starlink_accounts" link="show" allowEmpty>
            <TextField source="account_name" />
        </ReferenceField>
		<LinkFieldButton label="Device" source="device" basePath="devices" link="show" allowEmpty>
			<FunctionField render={record => `${record.name} / ${record.serial}`} />
		</LinkFieldButton>
        <StateChipField />
        <AlertsCountChipField source="last_alerts_count" sortable={false} />
        <TimeStampField label="Last online at" source="last_sync_at" showTime={true} />
        {children}
        <RecordSplitButton archiveButton={<></>} />
    </Datagrid>
);

StarlinkTerminalRow.defaultProps = {
    basePath: '/starlink_terminals'
};

export default StarlinkTerminalRow;
