import React from 'react';
import { TabbedShowLayout, Tab, TextField, NumberField, ReferenceManyField, Pagination, ReferenceField } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
// import CustomList from '../common/CustomList';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import PackageRow from '../packages/PackageRow';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';


// const ServiceList = props => (
// 	<CustomList
// 		filter={{ data_pool_id: props.record.id }}
// 		sort={{ field: 'id', order: 'DESC' }}
// 		{...props}
// 		resource="services"
// 		basepath="/services"
// 		title=" "
// 		hasCreate={false}
// 		hasEdit={false}
// 		hasList={false}
// 		hasShow={false}
// 	>
// 		<ServiceRow />
// 	</CustomList>
// );

const DataPoolTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Data Pool[${record.name}]`}</title>
		</Helmet>
		<span>{record.name}</span>
	</>
);

const DataPoolShow = props => (
	<Show title={<DataPoolTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="id" />
				<CustomerReferenceField />
				<ReferenceField label="Billing Address" source="invoicing_address_id" reference="addresses" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField label="Billing company" source="billing_company_id" reference="companies" link="show" allowEmpty>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="name" label="Datapool ID Reference" />
				<NumberField label="Data (GB)" sortable={false} source="data_included" />
				<NumberCurrencyField label="Price" source="data_price" />
				<NumberField label="Usage (GB)" sortable={false} source="current_usage" />
				<NumberField label="Extra (GB)" sortable={false} source="extra_usage" />
				<NumberCurrencyField label="Extra Price (EUR/GB)" source="data_extra_price" />
				<NumberField label="Packages count" sortable={false} source="packages_count" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
				{/* <ServiceList /> */}
			</Tab>
			<Tab label="Packages " path="packages">
				<ReferenceManyField addLabel={false} reference="packages" target="data_pool_id" pagination={<Pagination />}>
					<PackageRow />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default DataPoolShow;
