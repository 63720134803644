import React from 'react';

const InvoiceOdooUrlField = ({ record }) => {
    if (!record) return null;

    const odooUrl = record.odoo_data?.link || '';

    if (!odooUrl) return null;

    return (
        <a href={odooUrl} target="_blank" rel="noopener noreferrer">
            {record.odoo_id}
        </a>
    );
};

export default InvoiceOdooUrlField;
